<template>
  <div>
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>

    <v-layout wrap justify-center>
      <v-flex lg11>
        <v-layout wrap justify-center pt-10 px-5>
          <v-flex lg6>
            <span style="font-family: Montserrat-Bold; font-size: 28px"
              >Subscriptions</span
            >
          </v-flex>
          <v-flex lg6 class="text-end" align-self-end>
            <v-btn color="#00A08E" @click="addSubDialog = true">
              <span
                style="
                  color: white;
                  font-family: Montserrat-Regular;
                  font-size: 14px;
                  font-weight: 600;
                  text-transform: none;
                "
                >Add Subscription</span
              >
            </v-btn>
          </v-flex>
        </v-layout>

        <v-layout wrap justify-center>
          <v-flex lg12 pt-5>
            <v-layout wrap justify-start>
              <v-flex
                lg3
                md4
                sm6
                xs12
                v-for="(item, index) in Subscriptions"
                :key="index"
                class="px-5 pb-6"
              >
                <v-card
                  class="custom-card-size"
                  :style="{
                    backgroundColor:
                      item.status === 'Inactive' ? '#7e7e7edd' : '#00A08E',
                  }"
                  @click="
                    $router.push({ path: '/plan', query: { id: item._id } })
                  "
                >
                  <v-layout wrap justify-center>
                    <v-flex lg12 pa-5>
                      <v-layout wrap justify-center>
                        <v-flex lg12>
                          <span
                            style="
                              font-family: Montserrat-Regular;
                              font-size: 24px;
                              font-weight: 600;
                              color: white;
                            "
                            >{{ item.name }}</span
                          >
                        </v-flex>
                        <v-flex lg12>
                          <v-layout wrap>
                            <!-- <v-flex xs12 sm6 lg9>
                                        <span style="font-family: Montserrat-Regular; font-weight: 500; font-size: 15px">Subscription Type:</span>
                                        </v-flex> -->
                            <v-flex xs12 sm6 lg12>
                              <span
                                style="
                                  font-family: Montserrat-Regular;
                                  font-weight: 500;
                                  font-size: 16px;
                                  color: white;
                                "
                                >{{ item.description }}</span
                              >
                            </v-flex>
                          </v-layout>
                        </v-flex>

                        <v-flex lg12 pt-2>
                          <v-layout wrap>
                            <v-flex xs12 sm6 lg8>
                              <span
                                style="
                                  font-family: Lato-Regular;
                                  font-weight: 500;
                                  font-size: 15px;
                                  color: white;
                                "
                                >Subscription Type:</span
                              >
                            </v-flex>
                            <v-flex xs12 sm6 lg4>
                              <span
                                style="
                                  font-family: Lato-Regular;
                                  font-weight: 600;
                                  color: white;
                                  font-size: 16px;
                                "
                                >{{ item.isFree ? "Free" : "Paid" }}</span
                              >
                            </v-flex>
                          </v-layout>
                        </v-flex>

                        <v-flex lg12 pt-2>
                          <v-layout wrap>
                            <v-flex xs12 sm6 lg7>
                              <span
                                style="
                                  font-family: Lato-Regular;
                                  font-weight: 500;
                                  font-size: 15px;
                                  color: white;
                                "
                                >Advertisements:</span
                              >
                            </v-flex>
                            <v-flex xs12 sm6 lg5>
                              <span
                                style="
                                  font-family: Lato-Regular;
                                  font-weight: 600;
                                  color: white;
                                  font-size: 16px;
                                "
                                >{{
                                  item.isAdsIncluded ? "Enabled" : "Disable"
                                }}</span
                              >
                            </v-flex>
                          </v-layout>
                        </v-flex>

                        <v-flex lg12 pt-2>
                          <v-layout wrap>
                            <v-flex xs12 sm6 lg6>
                              <span
                                style="
                                  font-family: Lato-Regular;
                                  font-weight: 500;
                                  font-size: 15px;
                                  color: white;
                                "
                                >Accessibility:</span
                              >
                            </v-flex>
                            <v-flex xs12 sm6 lg6>
                              <span
                                style="
                                  font-family: Lato-Regular;
                                  font-weight: 600;
                                  color: white;
                                  font-size: 16px;
                                "
                                >{{
                                  item.isLimitedAccess ? "Limited" : "Full"
                                }}</span
                              >
                            </v-flex>
                          </v-layout>
                        </v-flex>

                        <!-- <v-flex lg12 pt-2>
                                    <v-layout wrap>
                                        <v-flex xs12 sm6>
                                        <span style="font-family: Montserrat-Regular; font-weight: 500; font-size: 15px">File Upload Limit:</span>
                                        </v-flex>
                                        <v-flex xs12 sm6>
                                        <span style="font-family: Lato-Regular; font-weight: 600; font-size: 16px">{{ item.isFileUploadLimited ? 'Enabled' : 'Disable' }}</span>
                                        </v-flex>
                                    </v-layout>
                                    </v-flex> -->

                        <!-- <v-flex lg12 pt-2>
                                    <v-layout wrap>
                                        <v-flex xs12 sm6>
                                        <span style="font-family: Montserrat-Regular; font-weight: 500; font-size: 15px">Upload Limit:</span>
                                        </v-flex>
                                        <v-flex xs12 sm6>
                                        <span style="font-family: Lato-Regular; font-weight: 600; font-size: 16px">{{ item.fileUploadLimit }} MB</span>
                                        </v-flex>
                                    </v-layout>
                                    </v-flex> -->
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

    <v-dialog
      v-model="addSubDialog"
      width="400px"
      content-class="dialog-custom"
    >
      <v-card>
        <v-layout wrap justify-center pa-6>
          <v-flex xs12 sm12 md12 lg12>
            <v-layout wrap justify-center>
              <v-flex xs11 sm11 md11 lg11 pb-3>
                <span
                  style="
                    font-family: Montserrat-Regular;
                    font-size: 24px;
                    font-weight: 700;
                  "
                  >New Subscription</span
                >
              </v-flex>
              <v-flex xs1 sm1 md1 lg1>
                <v-btn icon>
                  <v-icon @click="addSubDialog = false">mdi-close</v-icon>
                </v-btn>
              </v-flex>

              <v-flex xs12 sm12 md12 lg12>
                <v-layout wrap>
                  <v-flex lg6 pa-2>
                    <span
                      style="
                        font-family: Lato-Regular;
                        font-size: 16px;
                        font-weight: 500;
                      "
                      >Name</span
                    >
                    <v-text-field
                      outlined
                      dense
                      type="text"
                      placeholder="Name"
                      v-model="name"
                      hide-details="auto"
                    ></v-text-field>
                  </v-flex>

                  <v-flex lg6 pa-2>
                    <span
                      style="
                        font-family: Lato-Regular;
                        font-size: 16px;
                        font-weight: 500;
                      "
                      >Description</span
                    >
                    <v-text-field
                      outlined
                      dense
                      type="text"
                      placeholder="Description"
                      v-model="description"
                      hide-details="auto"
                    ></v-text-field>
                  </v-flex>

                  <v-flex lg6 pa-2>
                    <span
                      style="
                        font-family: Lato-Regular;
                        font-size: 16px;
                        font-weight: 500;
                      "
                      >Features</span
                    >
                    <v-combobox
                      v-model="features"
                      outlined
                      dense
                      chips
                      clearable
                      multiple
                      placeholder="Features"
                      hide-details="auto"
                    ></v-combobox>
                  </v-flex>

                  <v-flex lg6 pa-2>
                    <span
                      style="
                        font-family: Lato-Regular;
                        font-size: 16px;
                        font-weight: 500;
                      "
                      >Trial Period</span
                    >
                    <v-text-field
                      outlined
                      dense
                      type="text"
                      placeholder="Trial Period"
                      v-model="trialPeriod"
                      hide-details="auto"
                    ></v-text-field>
                  </v-flex>

                  <v-flex lg6 pa-2>
                    <v-layout wrap>
                      <v-flex lg5>
                        <span
                          style="
                            font-family: Lato-Regular;
                            font-size: 16px;
                            font-weight: 500;
                          "
                          >Is the plan Free</span
                        >
                      </v-flex>
                      <v-flex lg7>
                        <v-checkbox
                          outlined
                          dense
                          v-model="isFree"
                          hide-details="auto"
                        ></v-checkbox>
                      </v-flex>
                    </v-layout>
                  </v-flex>

                  <v-flex lg6 pa-2>
                    <v-layout wrap>
                      <v-flex lg7>
                        <span
                          style="
                            font-family: Lato-Regular;
                            font-size: 16px;
                            font-weight: 500;
                          "
                          >Is the Access Limited</span
                        >
                      </v-flex>
                      <v-flex lg5>
                        <v-checkbox
                          outlined
                          dense
                          v-model="isLimitedAccess"
                          hide-details="auto"
                        ></v-checkbox>
                      </v-flex>
                    </v-layout>
                  </v-flex>

                  <v-flex lg6 pa-2>
                    <v-layout wrap>
                      <v-flex lg8>
                        <span
                          style="
                            font-family: Lato-Regular;
                            font-size: 16px;
                            font-weight: 500;
                          "
                          >File Uploading Limited</span
                        >
                      </v-flex>
                      <v-flex lg4>
                        <v-checkbox
                          outlined
                          dense
                          v-model="isFileUploadLimited"
                          hide-details="auto"
                        ></v-checkbox>
                      </v-flex>
                    </v-layout>
                  </v-flex>

                  <v-flex lg6 pa-2>
                    <v-layout wrap>
                      <v-flex lg5>
                        <span
                          style="
                            font-family: Lato-Regular;
                            font-size: 16px;
                            font-weight: 500;
                          "
                          >Ad Included</span
                        >
                      </v-flex>
                      <v-flex lg7>
                        <v-checkbox
                          outlined
                          dense
                          v-model="isAdsIncluded"
                          hide-details="auto"
                        ></v-checkbox>
                      </v-flex>
                    </v-layout>
                  </v-flex>

                  <v-flex lg6 pa-2>
                    <v-layout wrap>
                      <v-flex lg7>
                        <span
                          style="
                            font-family: Lato-Regular;
                            font-size: 16px;
                            font-weight: 500;
                          "
                          >File Export Available</span
                        >
                      </v-flex>
                      <v-flex lg5>
                        <v-checkbox
                          outlined
                          dense
                          v-model="isFileExportAvailable"
                          hide-details="auto"
                        ></v-checkbox>
                      </v-flex>
                    </v-layout>
                  </v-flex>

                  <v-flex lg6 pa-2>
                    <v-layout wrap>
                      <v-flex lg8>
                        <span
                          style="
                            font-family: Lato-Regular;
                            font-size: 16px;
                            font-weight: 500;
                          "
                          >File Register Available</span
                        >
                      </v-flex>
                      <v-flex lg4>
                        <v-checkbox
                          outlined
                          dense
                          v-model="isFileRegisterAvailable"
                          hide-details="auto"
                        ></v-checkbox>
                      </v-flex>
                    </v-layout>
                  </v-flex>

                  <v-flex lg6 pa-2>
                    <span
                      style="
                        font-family: Lato-Regular;
                        font-size: 16px;
                        font-weight: 500;
                      "
                      v-if="isFileUploadLimited"
                      >File Upload Limit (MB)</span
                    >
                    <v-text-field
                      v-if="isFileUploadLimited"
                      outlined
                      dense
                      type="number"
                      placeholder="File Upload Limit"
                      v-model="fileUploadLimit"
                      hide-details="auto"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm12 md12 lg12 pa-2>
                    <v-layout wrap justify-center>
                      <v-flex lg6 pr-2>
                        <span
                          style="
                            font-family: Lato-Regular;
                            font-size: 16px;
                            font-weight: 500;
                          "
                          >Status</span
                        >
                        <v-select
                          outlined
                          dense
                          :items="statusList"
                          item-text="text"
                          item-value="text"
                          placeholder="Select Status"
                          v-model="status"
                          hide-details="auto"
                        ></v-select>
                      </v-flex>

                      <v-flex lg6 pl-lg-2>
                        <span
                          style="
                            font-family: Lato-Regular;
                            font-size: 16px;
                            font-weight: 500;
                          "
                          >Priority</span
                        >
                        <v-text-field
                          outlined
                          dense
                          v-model="planPriority"
                          type="number"
                          hide-details="auto"
                        ></v-text-field>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>

              <v-flex lg12 pt-5 class="text-center">
                <v-btn color="rgba(0, 160, 142, 1)" @click="addSubscription()">
                  <span
                    style="
                      color: white;
                      font-family: Montserrat-Regular;
                      font-size: 14px;
                      font-weight: 600;
                      text-transform: none;
                    "
                    >Submit</span
                  >
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      chip: true,
      showsnackbar: false,
      msg: null,
      addSubDialog: false,
      features: [], // Array for features
      name: "", // String for subscription name
      description: "", // String for description
      isFree: false, // Boolean for free status
      trialPeriod: "", // String for trial period
      isLimitedAccess: false, // Boolean for limited access
      isFileUploadLimited: false, // Boolean for file upload limit
      fileUploadLimit: 0, // Number for file upload limit
      isAdsIncluded: false, // Boolean for ads inclusion
      isFileExportAvailable: false,
      isFileRegisterAvailable: false,
      status: "Active", // String for status ("Active" or "Inactive")
      statusList: ["Active", "Inactive"], // List of possible statuses
      Subscriptions: [], // Array for subscriptions
      planPriority: "",
    };
  },
  mounted() {
    this.getSubscriptions();
    // this.getCategory();
  },
  watch: {
    currentPage() {
      this.getData();
    },
    search() {
      this.getData();
    },
  },
  methods: {
    getSubscriptions() {
      this.appLoading = true;
      axios({
        url: "/subscriptionPlan/list",

        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          // count: this.count,
          // page: this.currentPage,
          // keyword: this.search,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.Subscriptions = response.data.data;
          this.pages = response.data.pages;
          this.totalData = response.data.count;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getCategory() {
      this.appLoading = true;
      axios({
        url: "/course/catogory/list",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.categoryArray = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    addSubscription() {
      axios({
        method: "POST",
        url: "/subscriptionPlan/add",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          name: this.name,
          description: this.description,
          features: this.features, // Assuming this.features is an array of strings
          isFree: this.isFree,
          trialPeriod: this.trialPeriod,
          isLimitedAccess: this.isLimitedAccess,
          isFileUploadLimited: this.isFileUploadLimited,
          fileUploadLimit: this.fileUploadLimit,
          isAdsIncluded: this.isAdsIncluded,
          isFileExportAvailable: this.isFileExportAvailable,
          isFileRegisterAvailable: this.isFileRegisterAvailable,
          status: this.status, // Assuming status is either "Active" or "Inactive"
          planPriority: this.planPriority,
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.getSubscriptions(); // Assuming this method updates the relevant data
            this.addSubDialog = false;
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.custom-card-size {
  border-radius: 10px;
  height: 250px;
  background-image: #00a08e;

  /* width: 400px;  */
}
</style>